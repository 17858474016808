const selectLocality = document.getElementById('choose-locality');

if(selectLocality) {
  selectLocality.addEventListener('change', handleChange);
}

function handleChange() {
  const url = selectLocality.value;
  if( url !== "" ) {
    window.location = url;
  }
}
